/**
 * Event Node template.
 *
 * Each "event" node in Drupal that has the destination set to "Inside Sandy Springs"
 * will use this template to generate a corresponding page with Gatsby.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import InteriorLayout from '../components/interior-layout';
import EventDetail from '../components/event-detail';

const EventNode = ({ data }) => {
  const ratio10x7links = data?.nodeEvent?.srcSet?.ratio10x7?.links ?? {};
  const ratio10x7 = ratio10x7links
    ? Object.keys(ratio10x7links).map((value) => ratio10x7links[value].href)
    : [];

  // Depending on how much content there is, show more or fewer sidebar cards.
  let totalLength = 0;
  let sidebarDepth = 4;
  totalLength += data?.nodeEvent?.title.length;
  totalLength += data?.nodeEvent?.body.processed.length;

  const hasCta = data?.nodeEvent?.secondCta?.uri;

  if (totalLength > 2400 && totalLength < 4272) {
    sidebarDepth = 3;
  } else if (totalLength <= 2400) {
    sidebarDepth = 2;
  }

  return (
    <Layout>
      <InteriorLayout sidebarDepth={sidebarDepth}>
        <EventDetail
          srcSet={ratio10x7}
          alt={data?.nodeNews?.srcSet?.alt}
          title={data?.nodeEvent?.title}
          date={data?.nodeEvent?.field_date.value}
          body={data?.nodeEvent?.body.processed}
          path={data?.nodeEvent?.path?.alias}
          spektrix={data?.nodeEvent?.field_instances}
          tickets={{
            url: data?.nodeEvent?.field_tickets?.uri,
            text: data?.nodeEvent?.field_tickets?.title
          }}
          cta={
            hasCta && {
              url: data?.nodeEvent?.secondCta?.uri,
              text: data?.nodeEvent?.secondCta?.title
            }
          }
          tag={data?.nodeEvent?.relationships?.field_tags?.[0]?.name}
          parentTag={
            data?.nodeEvent?.relationships?.field_tags?.[0]?.relationships
              ?.parent[0]?.name
          }
        />
      </InteriorLayout>
    </Layout>
  );
};

EventNode.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query EventNodeQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    nodeEvent(path: { alias: { eq: $slug } }) {
      title
      drupal_id
      path {
        alias
      }
      body {
        processed
        summary
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
      srcSet: field_image {
        ...eventSrcSet10x7Fragment
      }
      relationships {
        field_tags {
          name
          relationships {
            parent {
              name
            }
          }
        }
      }
      field_date {
        value(formatString: "MMMM D, YYYY")
      }
      field_instances
      field_tickets {
        title
        uri
      }
      secondCta: field_subscription {
        title
        uri
      }
    }
  }
`;

export default EventNode;
