import React from 'react';
import styles from './interior-layout.module.scss';
import Card from '../card';
import CardList from '../card-list';
import { useEventsData } from '../../hooks/use-events-data';
import { useNewsData } from '../../hooks/use-news-data';
import { useDocumentsData } from '../../hooks/use-documents-data';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InteriorLayout = ({ children, sidebarDepth = 4 }) => {
  const news = useNewsData();
  const events = useEventsData();
  const documents = useDocumentsData();

  return (
    <div className={styles.grid}>
      <div className={styles.gridContainer}>
        <div className={styles.leftSideBar}>
          <div className={styles.gridItem}>
            <Card
              title={events[0]?.title}
              type={events[0]?.type}
              image={events[0]?.largeImage}
              tag={events[0]?.tag}
              parentTag={events[0]?.parentTag}
              date={events[0]?.dateShort}
              summary={events[0]?.summary}
              url={events[0]?.url}
            />
          </div>
          <div className={styles.gridItem}>
            <Card
              title={events[1]?.title}
              image={events[1]?.largeImage}
              type={events[1]?.type}
              tag={events[1]?.tag}
              parentTag={events[1]?.parentTag}
              date={events[1]?.dateShort}
              summary={events[1]?.summary}
              url={events[1]?.url}
            />
          </div>
          {sidebarDepth >= 3 && (
            <div className={styles.gridItem}>
              <Card
                title={events[2]?.title}
                type={events[2]?.type}
                image={events[2]?.largeImage}
                tag={events[2]?.tag}
                parentTag={events[2]?.parentTag}
                date={events[2]?.dateShort}
                summary={events[2]?.summary}
                url={events[2]?.url}
              />
            </div>
          )}
          {sidebarDepth === 4 && (
            <div className={styles.gridItem}>
              <CardList
                cards={events}
                heading="Latest Events"
                moreText="More Events"
                moreLink="/events"
              />
            </div>
          )}
        </div>
        <div className={styles.middleContainer}>{children}</div>
        <div className={styles.rightSideBar}>
          <div className={styles.gridItem}>
            <Card
              title={news[1].title}
              type={news[1].type}
              image={news[1].largeImage}
              tag={news[1].tag}
              parentTag={news[1].parentTag}
              date={news[1].dateShort}
              summary={news[1].summary}
              url={news[1].url}
              cardSummaryOnly={news[1].cardSummaryOnly}
            />
          </div>
          <div className={styles.gridItem}>
            <Card
              title={news[2].title}
              type={news[2].type}
              image={news[2].largeImage}
              tag={news[2].tag}
              parentTag={news[2].parentTag}
              date={news[2].dateShort}
              summary={news[2].summary}
              url={news[2].url}
              cardSummaryOnly={news[2].cardSummaryOnly}
            />
          </div>
          {/*
            This is a unique grid item in that we need to hide it for desktop view depending
            on the sidebar depth but show it for tablet view regardless of depth.
          */}
          <div
            className={classnames(styles.gridItem, {
              [styles.hideGridItem7]: sidebarDepth < 3,
              [styles.gridItem7]: true
            })}
          >
            <CardList
              cards={news}
              heading="Latest News"
              moreText="More News"
              moreLink="/news"
            />
          </div>
          {sidebarDepth === 4 && (
            <div className={classnames(styles.gridItem, styles.gridItem8)}>
              <CardList
                cards={documents}
                heading="Recent Documents"
                moreText="View More"
                moreLink="/documents"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InteriorLayout.propTypes = {
  children: PropTypes.node,
  sidebarDepth: PropTypes.number
};

export default InteriorLayout;
