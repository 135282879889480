import React from 'react';
import PropTypes from 'prop-types';
import WYSIWYG from '../wysiwyg';
import Button from '../button';
import ImgSrcset from '../img-srcset';

// Component styles.
import styles from './event-detail.module.scss';

const eventDetail = ({
  srcSet,
  alt,
  title,
  date,
  tag,
  parentTag,
  body,
  tickets,
  cta,
  spektrix,
  path
}) => (
  <div className={styles.eventDetailContainer}>
    <div className={styles.eventDetailContent}>
      <p className={styles.eventDetailEyebrow}>{parentTag ? parentTag : tag}</p>
      <div className={styles.eventDetailHeadings}>
        <h1 className={styles.eventTitle}>{title}</h1>
      </div>
      <p className={styles.eventDate}>{date}</p>
      <WYSIWYG className={styles.eventBody} body={body} />
      <div className={styles.eventCtas}>
        {tickets && (
          <Button
            aria-label={tickets.text}
            className={styles.cardButton}
            // Checking if ticket URL is a spektrix URL, we want to send the
            // user to the event on city springs.
            to={
              spektrix.length > 0
                ? `https://citysprings.com${path}`
                : tickets.url
            }
            borderStyle="round"
            color="blue"
            type="button"
          >
            {tickets.text || 'Buy Tickets'}
          </Button>
        )}
        {cta?.text && cta?.url && (
          <Button
            aria-label={cta.text}
            className={styles.eventButton2}
            to={cta.url}
            borderStyle="round"
            color="blue"
            type="button"
          >
            {cta.text}
          </Button>
        )}
      </div>
    </div>
    <div className={styles.eventDetailImageContainer}>
      {srcSet && (
        <ImgSrcset
          alt={alt}
          srcs={srcSet}
          className={styles.eventDetailImage}
          sizes={'(max-width: 576px) 98vw, (max-width: 795px) 80vw, 406px'}
        />
      )}
    </div>
  </div>
);

eventDetail.propTypes = {
  srcSet: PropTypes.array,
  alt: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  body: PropTypes.string,
  tickets: PropTypes.object,
  cta: PropTypes.object,
  tag: PropTypes.string,
  parentTag: PropTypes.string,
  spektrix: PropTypes.array,
  path: PropTypes.string
};

export default eventDetail;
